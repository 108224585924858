<template>
  <!-- <div id="background">
    <img v-if="false" src="./assets/artworks/a2.png" alt="" />
    <img :src="getRandomArtworkUrl()" alt="" />
  </div> -->
  <div class="app" :class="this.theme">
    <!--     :style="{
      'background-image': 'url(' + getRandomArtworkUrl() + ')',
      'background-size':  'auto 100%',
      'background-repeat': 'no-repeat',
      'background-attachment': 'scroll'
    }" -->
    <div id="background">
      <img :src="getArtworkUrl()" alt="background artwork" />
    </div>
    <!-- v-bind:style="{ 'padding-bottom': artworkPaddingTop + 'px' }" -->

    <!-- update for ServiceWorker found-->
    <div v-if="updateExists" class="update-wrapper" :class="this.theme">
      <div class="update">
        <div>a new version is available, please</div>
        <button @click="refreshApp" :class="this.theme">update</button>
      </div>
    </div>

    <div class="router-view-wrapper">
      <router-view />
      <Footer />
    </div>

    <Console class="Console" />
    <ErdjsFooter style="z-index: 999999; show: true" />
  </div>
</template>

<script>
import { mapState } from "pinia"
import Console from "@/components/Console.vue"
import Footer from "@/components/Footer.vue"

import updateServiceWorker from "@/mixins/updateServiceWorker"
import { useDefaultStore } from "@/store/default"

export default {
  name: "app",
  components: {
    Console,
    Footer
  },
  data() {
    return {
      initIfNoUserData: true,
      backgroundCountes: 15,
      backgroundIndex: null
      //artworkPaddingTop: "0",
      //artworkUrl: null
      //appHeight: 1500
    }
  },
  mixins: [updateServiceWorker],
  beforeCreate() {
    // always try init firebase vuex module :)
    // useDefaultStore().initFirebase()

    // force logout?
    console.log("APP this.$route.query.log", this.$route.query.log)
    if (this.$route.query.log === "out") {
      console.log("LOGOUT APP")
      return
    }

    if (!this.$erdjs.dapp.getAccountStore().getAddress) {
      return
    }

    console.log("INIT", this.$erdjs.dapp.getAccountStore().getAddress)

    // useDefaultStore().init(
    //   {
    //     getDappStore: this.$erdjs.dapp.getDappStore(),
    //     accountStore: this.$erdjs.dapp.getAccountStore(),
    //     loginInfoStore: this.$erdjs.dapp.getLoginInfoStore(),
    //     erdjs: this.$erdjs,
    //     wallet: this.$erdjs.dapp.getAccountStore().getAddress
    //   }
    // )

    // init
    const defaultStore = useDefaultStore()
    defaultStore.erdjs = this.$erdjs
    // defaultStore.dappStore = this.$erdjs.dapp.getDappStore()
    // defaultStore.accountStore = this.$erdjs.dapp.getAccountStore()
    // defaultStore.loginInfoStore = this.$erdjs.dapp.getLoginInfoStore()

    useDefaultStore().init()

    // console.log(
    //   'TOKEN TEST',
    //   this.$erdjs.dapp.getLoginInfoStore().getLoginMethod,
    //   this.$erdjs.dapp.getLoginInfoStore().getTokenLogin,
    //   this.$erdjs.dapp.getLoginInfoStore().getTokenLoginSignature,
    // )
    // console.log(
    //   `TEST`,
    //   defaultStore.accountStore,
    //   defaultStore.loginInfoStore,
    //   defaultStore.erdjs.dapp.getLoginInfoStore(),
    //   // defaultStore.accountStore.getAddress,
    //   // defaultStore.loginInfoStore.isLoggedIn,
    //   defaultStore.erdjs.dapp.getLoginInfoStore().isLoggedIn
    // )
  },
  watch: {
    $route(to, from) {
      this.changeArtworkUrl()
      window.scrollTo(0, 0)
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
    this.onResize()
    // start auto-refreshing the JWT/Cookie
    // window.setInterval(() => {
    //   this..dispatch("...", { forceRefresh: true });
    // }, 3480000);
  },
  computed: {
    ...mapState(useDefaultStore, ["theme"]),
    isLoggedIn() {
      return useDefaultStore()?.isAuthN()
    },
  },
  methods: {
    async logout() {
      await this.$erdjs.dapp.logout()
      await useDefaultStore().logout()
      this.$router.push("/?logout=false")
    },
    onResize() {
      useDefaultStore().updateScreenSize(window.innerWidth, window.innerHeight)
      // const footerSize = 700 // ~
      // let app = document.querySelector(".app")
      // this.appHeight = app.offsetHeight - footerSize
      // console.log(this.appHeight)
    },
    // onScroll(event) {
    //   //console.log("onScroll", event)
    //   let app = document.querySelector(".app")
    //   console.log(window.scrollY, "/", app.offsetHeight)

    //   const footerSize = 700 // ~
    //   let appScrollableSize = app.offsetHeight - footerSize

    //   // img = 100px
    //   // app = 1000px
    //   // scrollY = 300px -> 30%
    //   // const percentage = appScrollableSize /

    //   this.artworkPaddingTop = window.scrollY / 10
    // },
    changeArtworkUrl() {
      this.backgroundIndex =
        Math.floor(Math.random() * this.backgroundCountes) + 1
    },
    getArtworkUrl() {
      if (this.backgroundIndex === null) {
        this.backgroundIndex =
          Math.floor(Math.random() * this.backgroundCountes) + 1
      }
      var artworks = require.context("./assets/artworks/", false, /\.png$/)
      return artworks("./a_" + this.backgroundIndex + ".png")
    }
  }
}
</script>

<style scoped lang="scss">
#background {
  background: var(--theme-color-0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  & img {
    object-fit: contain;
    padding-top: 10%;
    width: calc(min(90vh, 90vw));
    opacity: 0.25;
    background-attachment: scroll;
  }
}

.update-wrapper {
  right: 0;
  top: 15px;
  width: 100%;
  position: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  /* align-items: center;
  align-content: center;
  text-align: center; */
}

.update {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  gap: 15px;
  z-index: 1;
  /* position: relative; */
  padding: 10px;
  background-color: var(--theme-color-4);
  color: var(--theme-color-2);
  border-radius: 4px;
  max-width: 95%;
  overflow: hidden;
}
.update.light {
  --background: white;
}

.update button:hover {
  transform: scale(1.01);
}

@media (max-width: 900px) {
  #background {
    overflow: hidden;

    & img {
      padding-top: 55%;
      width: calc(min(100vh, 100vw));

      overflow: hidden;
    }
  }
}
</style>
