import { useDefaultStore } from "@/store/default"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"
import firebase from "firebase/compat/app"
import { useLogger } from "@/store/logger"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home
  },
  // {
  //   path: "/authenticate",
  //   name: "VueErdjsConnect",
  //   component: VueErdjsConnect
  // },
  {
    path: "/profile/:to?",
    alias: ["/settings:to?", "/auth/:to?"],
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
    //meta: { authN: true }
  },
  {
    path: "/tools",
    name: "Tools",
    component: () => import(/* webpackChunkName: "tool" */ "../views/Tools.vue")
  },
  {
    path: "/tools/classic-generator",
    name: "Classic Generator",
    component: () =>
      import(/* webpackChunkName: "tool" */ "../views/Generator-classic.vue"),
    meta: { authN: true }
  },
  {
    path: "/tools/ai-generator/:collectionId?",
    name: "AI Generator",
    component: () =>
      import(/* webpackChunkName: "tool" */ "../views/Generator-ai.vue"),
    meta: { authN: true }
  },
  // {
  //   path: "/tools/ai-generator/:collectionId",
  //   name: "AI Generator",
  //   component: () =>
  //     import(/* webpackChunkName: "tool" */ "../views/Generator_ai/CollectionPage.vue"),
  //   meta: { authN: true }
  // },
  {
    path: "/ipfs",
    alias: ["/cids", "/cid"],
    name: "IPFS",
    component: () => import(/* webpackChunkName: "tool" */ "../views/IPFS.vue"),
    meta: { authN: true }
  },
  {
    path: "/about",
    alias: ["/nftea.studio"],
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/terms",
    alias: ["/terms-of-service"],
    name: "Terms",
    component: () =>
      import(
        /* webpackChunkName: "terms" */ "../views/Legal/TermsOfService.vue"
      )
  },
  {
    path: "/privacy",
    alias: ["/privacy-policy"],
    name: "Privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacy" */ "../views/Legal/PrivacyPolicy.vue"
      )
  },
  //{
  //  path: "/pay",
  //  alias: ["/payment", "/payments", "/wallet", "/credit", "/credits"],
  //  name: "payments",
  //  component: () =>
  //    import(/* webpackChunkName: "stripe" */ "../views/Stripe/Stripe.vue")
  //},
  // LEGAL
  //{
  //  path: "/legal/personal-data",
  //  alias: ["/legal/date-personale"],
  //  name: "personal-data",
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "legal" */ "../views/legal/personal-data.vue"
  //    )
  //},
  //{
  //  path: "/legal/privacy-policy",
  //  alias: ["/legal/politica-de-confidentialitate"],
  //  name: "privacy-policy",
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "legal" */ "../views/legal/privacy-policy.vue"
  //    )
  //},
  //{
  //  path: "/legal/return-form",
  //  alias: ["/legal/formular-de-retur"],
  //  name: "return-form",
  //  component: () =>
  //    import(/* webpackChunkName: "legal" */ "../views/legal/return-form.vue")
  //},
  //{
  //  path: "/legal/terms-and-conditions",
  //  alias: ["/legal/termeni-si-conditii"],
  //  name: "terms-and-conditions",
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "legal" */ "../views/legal/terms-and-conditions.vue"
  //    )
  //},
  //{
  //  path: "/legal/delivery-and-return",
  //  alias: ["/legal/livrare-si-retur"],
  //  name: "delivery-and-return",
  //  component: () =>
  //    import(
  //      /* webpackChunkName: "legal" */ "../views/legal/delivery-and-return.vue"
  //    )
  //},
  // ADMIN/DEV PAGES
  {
    path: "/dev",
    name: "/dev",
    component: () => import("../views/DevView.vue"),
    meta: { private: true }
  },
  // NOT FOUND
  {
    path: "/oops",
    name: "oops",
    component: () => import("../views/oops.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/oops"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   const currentUser = firebase.auth().currentUser
//   // console.log("(firebase) currentUser", currentUser)

//   const defaultStore = useDefaultStore()
//   const authN = to.matched.some((record) => record.meta.authN)

//   const isAuthenticated = (currentUser !== undefined) || defaultStore?.isLoggedIn

//   if (authN && !isAuthenticated) {
//     next({
//       path: "/auth",
//       query: { to: to.fullPath }
//     })
//   }
//   next()
// })

router.beforeEach((to, from, next) => {
  useLogger().log("(router) to", to)

  const defaultStore = useDefaultStore()
  const authN = to.matched.some((record) => record.meta.authN)
  // const authNZ

  let isAuthN

  // @ts-ignore: Object is possibly 'null'.
  if (this?.$erdjs) {
    isAuthN =
      // @ts-ignore: Object is possibly 'null'.
      this.$erdjs?.getDapp()?.getLoginInfoStore()?.isLoggedIn ??
      defaultStore?.isAuthN()
  } else {
    isAuthN = defaultStore.isAuthN()
  }

  useLogger().log("(router) isAuthN", isAuthN)

  if (authN && !isAuthN) {
    next({
      path: "/auth",
      query: { to: to.fullPath.replaceAll('/', '__') }
    })
  }
  next()
})


export default router
