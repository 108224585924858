import { defineStore } from "pinia"

export const useLogger = defineStore("logger", {
  state: () => ({
    debugLevel: 0 // all
  }),
  persist: true,
  actions: {
    // setDebugLevel(value: number) {
    //   this.debugLevel = value
    // },
    log(...messages: any[]) {
      if (this.debugLevel < 1) {
        console.log(...messages)
      }
    },
    info(...messages: any[]) {
      if (this.debugLevel < 2) {
        console.info(...messages)
      }
    },
    warn(...messages: any[]) {
      if (this.debugLevel < 3) {
        console.warn(...messages)
      }
    },
    error(...messages: any[]) {
      if (this.debugLevel < 4) {
        console.error(...messages)
      }
    }
  }
})
