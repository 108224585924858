<template>  <div class="footer-wrapper">
    <div class="links-wrapper">
      <div class="social">
        <div class="links">
          <a href="https://twitter.com/nfteastudio" class="button_hover">
            <font-awesome-icon alt="Twitter" :icon="['fab', 'twitter']"
          /></a>
          <a href="https://discord.gg/DPnZq2H6rS" class="button_hover">
            <font-awesome-icon alt="Discord" :icon="['fab', 'discord']"
          /></a>
          <a href="https://instagram.com/nfteastudio" class="button_hover">
            <font-awesome-icon alt="Instagram" :icon="['fab', 'instagram']"
          /></a>
          <a href="https://www.youtube.com/@NFTeaStudio" class="button_hover">
            <font-awesome-icon alt="YouTube" :icon="['fab', 'youtube']"
          /></a>
        </div>
      </div>
      <div class="legal">
        <div class="links">
          <a href="/terms" class="button_hover">Terms of Service</a>
          <a href="/privacy" class="button_hover">Privacy Policy</a>
          <!--Affiliate Program-->
          <a href="mailto:contact@nftea.studio" class="button_hover"
            >Contact Us</a
          >
        </div>
      </div>
    </div>
    <div>
      <div id="copyright">
        <p>Copyright © NFTea Studio. All rights reserved.</p>
      </div>
      <div id="theme-toggle" title="toggle theme">
        <a href="https://docs.nftea.studio" class="button_hover">docs</a>
        <p id="version">version 0.1.5</p>
        <font-awesome-icon
          v-show="this.theme == ''"
          :icon="['fas', 'moon']"
          id="theme_moon"
          class="button_hover"
          @click="toggleTheme()"
        />
        <font-awesome-icon
          v-show="this.theme == 'light'"
          :icon="['fas', 'sun']"
          id="theme_sun"
          class="button_hover"
          @click="toggleTheme()"
        />
      </div>
    </div>
  </div>
</template>

<script scoped>
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faTwitter,
  faDiscord,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"
library.add(faTwitter, faDiscord, faYoutube, faInstagram, faSun, faMoon)

import { mapState } from "pinia"
import { useDefaultStore } from '@/store/default'

export default {
  name: "Footer",

  computed: {
    ...mapState(useDefaultStore, ["theme"]),

  },
  methods: {
    toggleTheme() {
      useDefaultStore().toggleTheme()
    }
  }
}
</script>

<style lang="scss">
.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin-top: 25px;
  border-top: 1px solid;
  padding: 25px 0;
}

@media (max-width: 1250px) {
  // .footer-wrapper {
  //   // margin-bottom: 20px;
  // }
}

.links-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.legal,
.social {
  margin-bottom: 10px;
}

.links {
  display: flex;
  flex-direction: row;
}

.links a {
  padding: 5px 15px;
  color: var(--theme-color-2);
  font-size: smaller;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  text-align: center;
}

.links a svg {
  margin-left: 7.5px;
}

#version {
  text-align: center;
  align-content: center;
  align-items: center;
}

#copyright {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

#theme-toggle {
  margin-top: 15px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  gap: 25px;
  align-content: center;
  align-items: center;
  height: 50px;
}

#theme_sun {
  animation: rotation 44s linear infinite;

  transition: transform 0.44s;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
