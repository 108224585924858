<template>
  <div class="page home-page">
    <img v-if="innerWidth > 840" src="../assets/logo_desktop_white.png" id="main-logo" alt="logo" />
    <img v-else src="../assets/logo_mobile_white.png" id="main-logo" alt="logo" />
    <h1 id="moto">generate your NFTs, while you sip a cup of tea</h1>

    <div class="home-section">
      <!--❤️-->
      <div class="home-section-element">
        <h2 class="title">no code🔥</h2>
        <div>
          <!-- <ul>
            <li>simple</li>
            <li>text-to-image AI</li>
            <li>IPFS</li>
            <li>customizable settings</li>
            <li>any collection size</li>
            <li>.jpg, .png, .gif</li>
          </ul> -->
          <p>simple as f**k</p>
          <!-- <p>text-to-image AI</p> -->
          <p>IPFS (coming back soon)</p>
          <p>customizable settings</p>
          <p>any collection size</p>
          <p>.png .gif supported</p>
          <!-- .jpg, ... test with GIFs -->
        </div>
      </div>

      <div class="home-section-element">
        <h2 class="title">cheap 🪙</h2>
        <div>
          <p>pay for how much you use (PAYG)</p>
          <p>pay using crypto (EGLD/USD)</p>
          <p>no subscription needed</p>
        </div>
      </div>
    </div>

    <div class="home-section">
      <div class="home-section-element">
        <h2 class="title">blazing fast⚡</h2>
        <div>
          <p>high edge infrastructure</p>
          <p>optimized algorithms</p>
        </div>
      </div>
      <div class="home-section-element">
        <h2 class="title">universal🏛️</h2>
        <div>
          <p>universal output, compatible with any marketplace & blockchain</p>
        </div>
      </div>
    </div>

    <a href="https://docs.nftea.studio" target="_blank">
      <div class="get-started button_hover_1">check docs📜</div>
    </a>

    <div class="home-section roadmap">
      <div class="home-section-element">
        <h2 class="title">roadmap 2024 🏗️</h2>
        <div>
          <!-- <p>EARLY ALPHA release &#9745;&#65039;</p> -->
          <p>multiple IPFS providers ⌛</p>
          <p>recreate docs ⌛</p>
          <p>growing a community⌛</p>
          <p>BETA release with big surprises⌛</p>
          <p>Genesis NFTeas collection</p>
          <!--⌛-->
          <!-- <p>profit sharing SC</p>
          <p>BETA release</p>
          <p>extend team</p>
          <p>advanced rules and features</p>
          <p>validator nodes with more rewards</p>
          <p>MAIN release</p>
          <p>surprise</p>
          <p>more surprises</p> -->
        </div>
      </div>
    </div>

    <div class="home-section roadmap benefits">
      <div class="home-section-element">
        <div class="title">
          <h2>Genesis NFTeas</h2>
          <p>collection benefits🍵</p>
        </div>
        <div>
          <div>one time free credits worth of the minting price</div>
          <div class="benefit">daily free credits</div>
          <div class="benefit">discount (20% - 95%)</div>
          <div class="benefit">profit sharing</div>
          <div class="benefit">priority support</div>
          <div class="benefit">project support & promovation</div>
          <!-- <div class="benefit">DAO membership</div> -->
          <!-- <div class="benefit">artists community</div> -->
          <div class="benefit">whitelists</div>
          <div class="benefit">airdrops</div>
          <div class="benefit">and more :D</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia"
import { useDefaultStore } from '@/store/default'

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapState(useDefaultStore, ["theme", "innerWidth"]),
  },
}
</script>

<style scoped lang="scss">
.home-page {
  font-size: 7rem;
}

#main-logo {
  width: 100%;
  max-width: 90%;
  margin-top: 25px;
}

#moto {
  margin: 50px 0 100px 0;
  font-size: 44px;
  font-weight: 500;
}

.home-section {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  max-width: 95%;
  gap: 100px;
  margin-bottom: 100px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;

  & .home-section-element {
    width: 375px;
    max-width: 97.5%;
    background-color: var(--theme-color-1);
    padding: 25px 17.5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    & .title {
      margin-bottom: 20px;
      font-size: 36px;
      height: 50px;
    }

    & div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

.get-started {
  margin-top: 200px;
  background-color: var(--theme-color-1);
  width: 250px;
  padding: 25px 17.5px;
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.roadmap {
  margin-top: 200px;

  & .home-section-element {
    width: 500px;

    & div {
      gap: 5px;
    }
  }
}

.benefits {
  & .home-section-element {
    & .title {
      height: 100px;
    }
  }
}

/* .light * #main-logo {
  filter: invert(1);
} */

.blockchains {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  justify-items: center;
}
.blockchains img {
  border-radius: 50%;
  max-height: 55px;
  margin-right: 10px;
  transition: 0.44s ease;

  // -webkit-animation: blink-1 6s linear infinite;
  // -moz-animation: blink-1 6s linear infinite;
  // -ms-animation: blink-1 6s linear infinite;
  // -o-animation: blink-1 6s linear infinite;
  // animation: blink-1 6s linear infinite;
}
.blockchains img:hover {
  transform: scale(1.02);
  box-shadow: 0 0 10px white;
}

.home-background-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 80%;
}

.triangle {
  width: 200px;
  height: 200px;
  background: linear-gradient(
    to bottom right,
    #fff 0%,
    #fff 50%,
    #a48d01 50%,
    #a48d01 100%
  );
}

@media (max-width: 900px) {
  .home-section {
    flex-direction: column;
  }
}
</style>
