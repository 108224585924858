import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"

import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"

// Create app.
const app = createApp(App)

// Pinia init.
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

import "./registerServiceWorker"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"

import "nprogress/nprogress.css"

// global css
import "@/style/GlobalStyle.scss"
import "@/style/vue-erdjs.scss"
import "@/style/firebaseui-custom.scss"

// erdjsVue
const { erdjsVue } = require("erdjs-vue")
const erdjs = erdjsVue(
  {
    loadCss: false,
    chain: process.env.VUE_APP_MVX_ENV,
    piniaInstance: pinia
  },
  {
    walletConnectV2ProjectId: process.env.VUE_APP_WC_PROJECT_ID
  },
  {
    shouldUseWebViewProvider: true,
    ledgerLogin: {
      nativeAuth: true
    },
    tokenLogin: {
      nativeAuth: true
    },
    walletLogin: {
      nativeAuth: true
    },
    extensionLogin: {
      nativeAuth: true
    },
    walletConnectLogin: {
      nativeAuth: true
    }
  }
)

console.log("wc", process.env.VUE_APP_WC_PROJECT_ID)

// pinia store
// import { useDefaultStore } from "./store/default"
// const defaultStore = useDefaultStore()

// firebase authentication
import "./helpers/firebase"
import "firebaseui/dist/firebaseui.css"
import firebase from "firebase/compat/app"
import * as firebaseui from "firebaseui"
const firebaseConfig = {
  apiKey: "AIzaSyCpCpBgyWx5OloThfCUzt9ZV32n1M9P-o8",
  authDomain: "nftea-studio.firebaseapp.com",
  projectId: "nftea-studio",
  storageBucket: "nftea-studio.appspot.com",
  messagingSenderId: "925475861681",
  appId: "1:925475861681:web:738f4a6bcff364d0309e90",
  measurementId: "G-0HLKK02SDJ"
}

firebase.initializeApp(firebaseConfig)
//firebase.analytics();
const ui = new firebaseui.auth.AuthUI(firebase.auth())
app.config.globalProperties.$firebase = firebase
app.config.globalProperties.$firebaseui = ui

app.config.globalProperties.initialized = false


firebase.auth().onAuthStateChanged((userData) => {
  if (app.config.globalProperties.initialized) {
    return
  } else {
    console.log("already mounted")
    app.config.globalProperties.initialized = true
  }

  // console.log("(firebase) onAuthStateChanged", userData)
  
  app.use(router)
  app.use(erdjs)
  app.use(VueSweetalert2)
  app.component("font-awesome-icon", FontAwesomeIcon)
  app.mount("#app")
})
