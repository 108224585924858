<template>  <div class="console">
    <div class="bar">
      <div class="items">
        <div
          class="home_button item"
          v-bind:class="{ active: this.$route.name == 'Home' }"
          @click="$router.push({ path: '/' })"
        >
          <font-awesome-icon class="tea" alt="home button" icon="mug-hot" />
        </div>

        <div
          class="tools_button item"
          v-bind:class="{ active: this.$route.name == 'Tools' }"
          @click="$router.push({ path: '/tools' })"
        >
          <font-awesome-icon
            alt="tools button"
            icon="layer-group"
            class="tools"
          />
        </div>

        <div
          class="user-circle item"
          v-bind:class="{
            active: this.$route.name == 'Auth' || this.$route.name == 'Profile'
          }"
          @click="$router.push({ path: '/profile' })"
        >
          <font-awesome-icon
            :icon="['fas', 'user']"
            alt="authenticate profile button"
            class="user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faUser,
  faCaretLeft,
  faMugHot,
  faLayerGroup
} from "@fortawesome/free-solid-svg-icons"
library.add(faUser, faCaretLeft, faMugHot, faLayerGroup)

export default {
  name: "Console",
  computed: {
    currentRoutePath() {
      return this.$router.getcurrentRoutePath
    },
    isLoggedIn() {
      return this.$erdjs.getDapp().getLoginInfoStore().isLoggedIn
    }
  }
}
</script>

<style scoped>
.console {
  z-index: 100;
  display: flex;
  justify-content: center;
  --align-content: center;
  --align-items: center;
  position: fixed;
  width: 100vw;
  padding-left: auto;
  padding-right: auto;
  bottom: 0;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
  border-top: 1px solid var(--theme-color-2);

  --background: rgba(255, 255, 255, 0.25);
  /* background: #00000040;*/
  background: var(--theme-color-1);
  /* background-color: rgba(#2c2e39, 1); */
}
.console::-webkit-scrollbar {
  width: 0;
}
.console.light {
  --background: rgba(18, 18, 18, 0.5);
}

.bar {
  width: 100%;
  max-width: 500px;
  border-radius: 4px 4px 0% 0%;
  color: white;
}
.bar::-webkit-scrollbar {
  width: 0;
}

.item {
  width: 35px;
  height: 35px;
  object-fit: contain;
  color: #252a34;
  transition: 0.44s ease;
  box-shadow: 0 0 2px white;
  border-radius: 50%;
  overflow: hidden;
}
.item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px white;
}
.light * .item:hover {
  border-radius: 1px solid #252a34;
  box-shadow: 0 0 10px white;
}

.layers-item {
  border-radius: 0;
  overflow: show;
}
.item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px white;
}
.light * .item:hover {
  border-radius: 1px solid #252a34;
  box-shadow: 0 0 10px white;
}

.user-circle {
  background: white;
  color: #121212;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}
.user {
  width: 35px;
  height: 35px;
  padding: 8px 0px 0px 0px;
}

.home_button {
  --border-radius: 100%;
  background: white;
  --filter: invert(0);
  height: 100%;
  height: 55px;
  width: 55px;
}
.home_button.light {
  background: white;
  --filter: invert(1);
}

.tools_button_1 {
  height: 55px;
  width: 55px;
  --padding: 10px;
  background: white;
  padding: 10px;
}

.tools_button {
  border-radius: 100%;
  padding: 12px;
  background: white;
  filter: invert(0);
  height: 55px;
  width: 55px;
}
.tools_button {
  border-radius: 100%;
  padding: 12px;
  background: white;
  filter: invert(0);
  height: 55px;
  width: 55px;
}
.tools_button.light {
  background: white;
  --filter: invert(1);
}
.tools {
  width: 33px;
  height: 33px;
  --padding: 8px 7px 8px 14px;
}

.tea {
  width: 35px;
  height: 35px;
  padding: 8px 7px 8px 14px;
}

.items {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  background: transparent;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.items.light {
  background: transparent;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.user_photo_icon {
  border-radius: 100%;
}

.active {
  -webkit-animation: blink 3s linear infinite;
  -moz-animation: blink 3s linear infinite;
  -ms-animation: blink 3s linear infinite;
  -o-animation: blink 3s linear infinite;
  animation: blink 3s linear infinite;
}

.light * .active {
  -webkit-animation: blink 3s linear infinite;
  -moz-animation: blink 3s linear infinite;
  -ms-animation: blink 3s linear infinite;
  -o-animation: blink 3s linear infinite;
  animation: blink 3s linear infinite;
}

@media (min-width: 400px) and (orientation: landscape) {
  .console {
    height: 100%;
    max-width: 70px;
    overflow-y: scroll;
    --background: rgba(255, 255, 255, 0.2);
    --background: rgba(0, 0, 0, 0.25);
    border-top: 0;
  }

  .bar {
    width: 100%;
    background: transparent;
    display: flex;
    --flex-direction: column;
    --align-items: center;
    --align-content: center;
    --justify-content: center;
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    background: transparent;
  }
  .item {
    margin: calc(min(5vh, 50px)) 0;
  }
  .icon-wrapper {
    margin: auto;
  }
  .icon-wrapper:hover {
    color: rgb(255, 102, 0);
    background: transparent;
    min-width: 19%;
    overflow: hidden;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 10px;
    height: auto;
    --padding: 5px 8px;
    --border-radius: 5px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  .home_button {
    color: #252a34;
  }
  .home_button:hover {
    --height: 115px;
  }
  .button-title {
    --font-size: 1rem;
  }
  .bar {
    border-top: none;
    width: auto;
    max-width: 200px;
    overflow-y: scroll;
  }
}
</style>
