import { defineStore } from "pinia"
import { useDefaultStore } from "./default"
const { base64toUrl } = require("../helpers/Utils")
import { useLogger } from "./logger"

export const useNetApiStore = defineStore("netApi", {
    state: () => ({
        debug: true,
        collections: [],
        collections_sas: [], // {} as { [key: string]: any }
        collections_states: [], // {} as { [key: string]: any },
        collections_cids: [],
        user_cids: [],

        // OLD METHODS
        // collection updating status
        collection_isUpdating: false,
        // PREVIEWs (as blobs & object URLs (URL.createObjectURL))
        //previews: [],
        previews_URLs: [],
        // OUTPUT FILES
        //fileLists: [],
        // SUPPORTED FORMATS
        supportedMediaFormats: [".png", ".gif", ".jpg", ".jpeg"],
        //
        credits: undefined,
        discount: undefined,
        nfteasCount: undefined,
        nfteasStakedCount: undefined,
        creditPriceUsdDenominated: undefined,
        egldUsdPriceDenominated: undefined,
        nfteasDiscountLevels: undefined,
    }),
    persist: true,
    getters: {},
    actions: {
        // MUTATIONS
        SET_COLLECTIONS(collections: any) {
            this.$state.collections = collections
        },
        SET_COLLECTION(collection: any) {
            if (
                this.$state.collections.filter((c: any) => c.id == collection.id).length > 0
            ) {
                // force update
                let idx = this.$state.collections.findIndex((c: any) => c.id == collection.id)
                let firstCollections = this.$state.collections.slice(0, idx)
                let lastCollections = this.$state.collections.slice(idx + 1)

                this.$state.collections = firstCollections
                    .concat(collection)
                    .concat(lastCollections)
            } else {
                (this.$state.collections as any).push(collection)
            }
        },
        SET_COLLECTION_SAS(params: any) {
            (this.$state.collections_sas as any)[params.collectionId] = params.sas
        },
        SET_COLLECTION_STATE(data: any) {
            (this.$state.collections_states as any)[data.id] = data
            // (this.$state.collections_states as any)[state.id] = collectionState
            // console.log("state.collections_states <=", this.$state.collections_states)
        },
        SET_COLLECTION_CIDS(params: any) {
            (this.$state.collections_cids as any)[params.collectionId] = params.cids
            // console.log("state.collections_cids <=", this.$state.collections_states)
        },
        SET_USER_CIDS(params: any) {
            this.$state.user_cids = params.cids
            // console.log("state.collections_cids <=", this.$state.collections_states)
        },
        async REMOVE_COLLECTION_LAYER(params: any) {
            let collection: any = this.$state.collections.filter(
                (c: any) => c.id == params.collectionId
            )[0]

            //console.log("REMOVE_COLLECTION_LAYER found collection", collection);

            collection.layers = collection.layers.filter((layer: any) => {
                return layer.id !== params.layerId
            })
        },
        async REMOVE_COLLECTION(params: any) {
            this.$state.collections = this.$state.collections.filter(
                (c: any) => c.id != params.collectionId
            )
        },
        ADD_PREVIEW(params: any) {
            if (this.$state.previews_URLs[params.collectionId] == null) {
                //state.previews[params.collectionId] = [params.preview]
                (this.$state.previews_URLs as any)[params.collectionId] = [
                    base64toUrl(params.preview.bytes, params.preview.type)
                    //URL.createObjectURL(base64toUrl(params.preview.bytes, params.preview.type))
                ]
            } else {
                //state.previews[params.collectionId].push(params.preview)
                (this.$state.previews_URLs as any)[params.collectionId].push(
                    base64toUrl(params.preview.bytes, params.preview.type)
                    //URL.createObjectURL(base64toUrl(params.preview.bytes, params.preview.type))
                )
            }
        },
        CLEAR_PREVIEWS(collectionId: any) {
            //state.previews[collectionId] = []
            (this.$state.previews_URLs as any)[collectionId] = []
        },
        DELETE_COLLECTIONS_SESSION() {
            this.$state.collections = []
            this.$state.collections_sas = []
            this.$state.collections_states = []
            this.$state.collection_isUpdating = false
            this.$state.previews_URLs = []
            // console.log("DELETE_COLLECTIONS_SESSION")
        },
        SET_USER_CREDITS(credits: any) {
            // console.log(`SET_USER_CREDITS`, credits)
            this.$state.credits = credits
        },
        SET_USER_SESSION_DATA(userSessionData: any) {
            this.$state.credits = userSessionData.userData.credits
            this.$state.discount = userSessionData.discount
            this.$state.nfteasCount = userSessionData.nfteasCount
            this.$state.nfteasStakedCount = userSessionData.nfteasStakedCount
            this.$state.egldUsdPriceDenominated = userSessionData.egldUsdPriceDenominated
            this.$state.creditPriceUsdDenominated =
              userSessionData.creditPriceUsdDenominated
              this.$state.nfteasDiscountLevels = userSessionData.nfteasDiscountLevels
        },
        // ACTIONS
        async init() {
            this.$state.collections_states = [] as any
            (this.$state.collections_states as any)[0] = {
                id: 0,
                state: "init"
            } as any
            this.$state.collections_sas = []

            await Promise.all([
                this.getAndSetUserCollectionsV1(),
                this.getUserSessionData()
            ])
            await this.getAndSetUserCollectionsV1();
        },
        async logout() {
            this.DELETE_COLLECTIONS_SESSION();
        },
        /* COLLECTIONS */
        async getUserSessionData() {
            return await this.http({
                method: "get",
                path: "wallet/" + (useDefaultStore() as any).erdjs.dapp.getAccountStore().getAddress
            }).then((response: { data: any }) => {
                // if (state.debug) console.log("getUserSessionData =>", response.data)
                this.SET_USER_SESSION_DATA(response.data)
              })
              .catch((error: any) => {
                console.log(error)
              })
          },
        async getAndSetUserCollectionsV1() {
            const defaultStore = useDefaultStore();
            try {
                const response = await this.http({
                    method: "GET",
                    path: "collections/v1"
                });
                if (this.debug) console.log("getAndSetUserCollectionsV1 =>", response.data);
                this.SET_COLLECTIONS(response.data);
            } catch (error) {
                console.log(error);
            }
        },
        async createCollectionV1(params: any) {
            if (this.debug) console.log("addCollectionV1() <= ", params);
            try {
                const response = await this.http({
                    method: "post",
                    path: `collections/v1/create/${params.collectionName}`
                });
                if (this.debug) {
                    console.log("createCollectionV1() => ", response.data);
                }
                this.SET_COLLECTION(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async deleteCollectionV1(params: any) {
            if (this.debug) console.log("deleteCollectionV1() <= ", params);
            try {
                const response = await this.http({
                    method: "delete",
                    path: "collections/v1/" + params.collectionId
                });
                if (this.debug) {
                    console.log("deleteCollectionV1() => ", response.data);
                }
                this.REMOVE_COLLECTION({ collectionId: params.collectionId });
                return response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async updateCollectionV1(params: any) {
            if (this.collection_isUpdating) {
                if (this.debug)
                    console.log("updateCollectionNameV1() : collection_isUpdating, return");
                return {
                    alert: "Collection already updating. Please try again. If still not working, please refresh."
                };
            } else {
                this.collection_isUpdating = true;
            }

            try {
                const response = await this.http({
                    method: "post",
                    path: "collections/v1/" + params.collection.id,
                    data: params.collection
                });
                this.SET_COLLECTION(response.data);
                this.collection_isUpdating = false;
                return response.data;
            } catch (error) {
                this.collection_isUpdating = false;
                console.log(error);
                return { alert: (error as Error) };
            }
        },
        async getCollectionSASV1(collectionId: string) {
            try {
                const response = await this.http({
                    method: "get",
                    path: "collections/v1/" + collectionId + "/sas"
                });
                this.SET_COLLECTION_SAS({
                    collectionId: collectionId,
                    sas: response.data
                });
                return response.data;
            } catch (error) {
                console.log("getCollectionSASV1", error);
                return { alert: (error as Error) };
            }
        },
        async getCollectionStateV1(collectionId: string) {
            try {
                const response = await this.http({
                    method: "get",
                    path: "collections/v1/" + collectionId + "/state"
                });
                this.SET_COLLECTION_STATE(response.data);
                return response.data;
            } catch (error) {
                console.log("getCollectionStateV1", error);
                return { alert: (error as Error) };
            }
        },
        /* LAYERS */
        async deleteLayerV1(params: any) {
            if (this.debug) console.log("deleteLayerV1() <= ", params);

            // build the last part of the layer/folder path
            params.path = "input_" + params.layerId + "_";

            // remove layer
            this.REMOVE_COLLECTION_LAYER({
                collectionId: params.collectionId,
                layerId: params.layerId
            });

            // update params.collection for backend update
            params.collection = this.collections.filter(
                (c) => (c as any).id == params.collectionId
            )[0];

            await this.updateCollectionV1(params);
        },
        /* ASSETS */
        async addAssetsV1(params: any) {
            if (this.collection_isUpdating) {
                if (this.debug)
                    console.log("updateCollectionNameV1() : collection_isUpdating, return");
                return {
                    alert: "Collection already updating. Please try again. If still not working, please refresh."
                };
            } else {
                this.collection_isUpdating = true;
            }

            if (!params.collectionId && !params.layerId) {
                params.collectionId = (this.collections as any)[params.collection_index].id;
                params.layerId = (this.collections as any)[params.collection_index].layers[params.layer_index].id;
            }

            try {
                const response = await this.http({
                    method: "post",
                    path: "collections/" + params.version + "/" + params.collectionId + "/" + "input" + "/" + params.layerId,
                    data: params.formData
                });
                if (this.debug) {
                    console.log("addAssetsV1() => ", response.data);
                }
                this.SET_COLLECTION(response.data);
                this.collection_isUpdating = false;
                return response.data;
            } catch (error) {
                this.collection_isUpdating = false;
                console.log(error);
                return { alert: "Something went wrong..." + (error as Error) };
            }
        },
        async deleteAssetV1(params: any) {
            if (this.debug) console.log("deleteAssetV1 <= ", params);

            let modifiedLayer: any = (this.collections as any)[params.collection_index].layers[params.layer_index];
            modifiedLayer.assets = modifiedLayer.assets.filter((asset: any) => {
                return asset.name !== params.assetName;
            });
            params.collection = this.collections[params.collection_index];
            await this.updateCollectionV1(params);
        },
        /* PREVIEWS */
        async getQuickPreviewV1(params: any) {
            if (this.debug) console.log("getQuickPreviewV1() <= ", params);

            try {
                const response = await this.http({
                    method: "get",
                    path: "collections/v1/generateQuickSample/" + params.collectionId
                });
                this.ADD_PREVIEW({
                    collectionId: params.collectionId,
                    preview: response.data
                });
                this.SET_USER_CREDITS(response.data.credits);
                return response;
            } catch (error) {
                return (error as any)?.response;
            }
        },
        async clearQuickPreviewsV1(collectionId: string) {
            this.CLEAR_PREVIEWS(collectionId);
            console.log("clearQuickPreviewsV1");
        },
        /* GENERATOR <3 */
        async generate(params: any) {
            try {
                const response = await this.http({
                    method: "post",
                    path: `collections/v1/${params.collectionId}/generate`
                });
                console.log("generate() =>", response);
                this.SET_COLLECTION_STATE(response.data);
                return response;
            } catch (error) {
                console.log(error);
                return { alert: error };
            }
        },
        async getFilesV1(params: any) {
            if (params.presignURLs == null) params.presignURLs = "false";

            try {
                const response = await this.http({
                    method: "get",
                    path: "collections/v1/" + params.collectionId + "/" + params.prefix.replace("/", "_") + "/" + params.presignURLs
                });
                return response.data;
            } catch (error) {
                return { alert: (error as Error) };
            }
        },
        async downloadFileV1(params: any) {
            try {
                const response = await this.http({
                    method: "get",
                    path: "/download/" + params.collectionId + "/" + params.path,
                    headers: params.isMedia ? { responseType: "blob" } : {}
                });
                if (response.status == 200) {
                    return response;
                } else {
                    return { alert: "Not found", data: null };
                }
            } catch (error) {
                return { alert: (error as Error), data: null };
            }
        },
        async buildFileSAS(params: any) {
            if (this.collections_sas?.[params.collectionId]) {
                return (this.collections_sas[params.collectionId] as any).replace("?", params.prefix + "?");
            }
        },
        async getOutputSignedURL() {
            try {
                const response = await this.http({
                    method: "get",
                    path: "http://localhost:7072/api/test_scratch0"
                });
                console.log("getOutputSignedURL() =>", response);
                return response.data;
            } catch (error) {
                console.log("getOutputSignedURL() =>", error);
                return { alert: (error as Error) };
            }
        },
        // CIDs
        async getCollectionCids(collectionId: string) {
            try {
                const response = await this.http({
                    method: "get",
                    path: "cids/" + collectionId
                });
                this.SET_COLLECTION_CIDS({
                    collectionId: collectionId,
                    cids: response.data
                });
                return response.data;
            } catch (error) {
                console.log("getCollectionCids", error);
                return { alert: (error as Error) };
            }
        },
        async getUserCids(params: any) {
            try {
                const response = await this.http({
                    method: "get",
                    path: "cids/" + params.wallet,
                });
                this.SET_USER_CIDS({ cids: response.data });
                return response.data;
            } catch (error) {
                console.log("getUserCids", error);
                return { alert: (error as Error) };
            }
        },
        async deleteCid(params: any) {
            try {
                const response = await this.http({
                    method: "delete",
                    path: `collections/${params.collectionId}/cids/${params.cidDocumentId}`
                });
                this.SET_USER_CIDS({ cids: response.data });
                return response.data;
            } catch (error) {
                console.log("getUserCids", error);
                return { alert: (error as Error) };
            }
        },
        async updateCidLock(params: any) {
            try {
                const response = await this.http({
                    method: "post",
                    path: `collections/${params.collectionId}/cids/${params.cidDocumentId}/${params.locked}`
                });
                this.SET_COLLECTION_CIDS({
                    collectionId: params.collectionId,
                    cids: response.data
                });
                return response.data;
            } catch (error) {
                console.log("updateCidLock", error);
                return { alert: (error as Error).message };
            }
        },
        // HTTP middleware
        async http(params: any) {
            const baseUrl = process.env.VUE_APP_NET_API
            const defaultStore = useDefaultStore()
            return await defaultStore.http({baseUrl, ...params})
        }
    }
})
