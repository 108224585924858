import axios from "axios"//import NProgress from "nprogress";
const NProgress = require("nprogress")

var ApiService = axios.create()

/* INTERCEPTORS <3 */
// progress bar feature
let requests_cnt = 0
ApiService.interceptors.request.use(
  (config) => {
    NProgress.start()
    requests_cnt++
    return config
  },
  (error) => {
    //console.log("AXIOS INTERCEPTORS -> request ERROR", error);
    return error
  }
)

ApiService.interceptors.response.use(
  (response) => {
    if (--requests_cnt == 0) NProgress.done()
    return response
  },
  (error) => {
    //console.log("AXIOS INTERCEPTORS -> response ERROR", error);
    if (--requests_cnt == 0) NProgress.done()
    throw error
  }
)

export default ApiService
