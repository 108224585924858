import { defineStore } from 'pinia';
import { useDefaultStore } from './default';
import { useLogger } from "./logger"

export const useNodeApiStore = defineStore('nodeStore', {
  state: () => ({
    debug: true,
    collections: {
      list: [],
      count: 0,
      skip: 0,
      limit: undefined
    },
    currentCollection: undefined,
    currentImages: undefined,
    currentRequests: undefined
  }),
  actions: {
    SET_COLLECTIONS(collections: any) {
      this.collections = collections;
    },
    SET_CURRENT_COLLECTION(collection: any) {
      this.currentCollection = collection;
    },
    SET_CURRENT_IMAGES(images: any) {
      this.currentImages = images;
    },
    DELETE_SESSION() {
      this.collections = {
        list: [],
        count: 0,
        skip: 0,
        limit: undefined
      };
      this.currentCollection = undefined;
      this.currentImages = undefined;
    },
    SET_CURRENT_REQUESTS(requests: any) {
      this.currentRequests = requests;
    },
    async init() {
      this.DELETE_SESSION();
      await this.getAndSetUserCollectionsAi();
    },
    async logout() {
      this.DELETE_SESSION();
    },
    async getAndSetUserCollectionsAi(params?: any) {
      try {
        const response = await this.http({
          method: "get",
          path: `collections-ai?skip=${params?.skip ?? 0}&limit=${params?.limit ?? 10}`
        });
        if (this.debug) console.log("getAndSetUserCollectionsAi =>", response.data);
        this.SET_COLLECTIONS(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCollectionAi(params: any) {
      await this.getCollectionAiImages({ collectionId: params.id ?? params.collectionId });
      await this.getCollectionAiImageRequests({ collectionId: params.id ?? params.collectionId });
      
      try {
        const response = await this.http({
          method: "get",
          path: `collections-ai?id=${params.id}`
        });
        if (this.debug) console.log("getCollectionAi =>", response.data);
        this.SET_CURRENT_COLLECTION(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createCollectionAi(params: any) {
      try {
        const response = await this.http({
          method: "post",
          path: "collections-ai",
          data: params.data
        });
        if (this.debug) console.log("createCollectionAi() => ", response.data);
        await this.getAndSetUserCollectionsAi(params);
        this.SET_CURRENT_COLLECTION(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async deleteCollectionAi(params: any) {
      try {
        const response = await this.http({
          method: "delete",
          path: `collections-ai?id=${params.id}`,
          data: params.data
        });
        if (this.debug) console.log("deleteCollectionAi() => ", response.data);
        await this.getAndSetUserCollectionsAi(params);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async updateCollectionAi(params: any) {
      try {
        const response = await this.http({
          method: "put",
          path: "collections-ai",
          data: params.data
        });
        if (this.debug) console.log("updateCollectionAi() => ", response.data);
        await this.getAndSetUserCollectionsAi(params);
        this.SET_CURRENT_COLLECTION(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async getCollectionAiImages(params: any) {
      try {
        const response = await this.http({
          method: "get",
          path: `collections-ai/images?collectionId=${params.collectionId}`
        });
        if (this.debug) console.log("getCollectionAiImages =>", response.data);
        this.SET_CURRENT_IMAGES(response.data.list);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCollectionAiImageRequests(params: any) {
      try {
        const response = await this.http({
          method: "get",
          path: `collections-ai/requests?collectionId=${params.collectionId ?? params.data.collectionId}`
        });
        if (this.debug) console.log("getCollectionAiImageRequests =>", response.data);
        this.SET_CURRENT_REQUESTS(response.data.list);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createCollectionAiImageRequest(params: any) {
      try {
        const response = await this.http({
          method: "post",
          path: "collections-ai/requests",
          data: params.data
        });
        if (this.debug) console.log("createCollectionAiImageRequest() => ", response.data);
        await this.getCollectionAiImageRequests(params);
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async http(params: any) {
        const baseUrl = process.env.VUE_APP_NODE_API
        const defaultStore = useDefaultStore()
        return await defaultStore.http({baseUrl, ...params})
    },
  }
});
