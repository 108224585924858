import { defineStore } from "pinia"
import Axios from "@/helpers/ApiService"
import * as jose from "jose"
import { useNodeApiStore } from "./node_api"
import { useNetApiStore } from "./net_api"
import { useLogger } from "./logger"
// import { decodeBase64 } from "@/helpers/Utils"
const {
  decodeBase64,
} = require("../helpers/Utils")

export const useDefaultStore = defineStore("default", {
  state: () => ({
    // token: undefined,
    erdjs: undefined,
    loggedInTime: undefined,
    maintenance: false,
    // debug: true,
    innerWidth: undefined,
    innerHeight: undefined,
    theme: "", // '' or 'light'
    // consent: false, // cokies
    init: false, // data from backend fetched?
    init_in_progress: false, // data is fetching from backend
    user: undefined // for firebase merge <3 // maybe additional folders for auth or something...
  }),
  persist: true,
  // getters: {
  //   isLoggedIn: (state) =>
  //     // @ts-ignore: Object is possibly 'null'.
  //     state.erdjs?.dapp?.getLoginInfoStore()?.isLoggedIn
  //   // @ts-ignore: Object is possibly 'null'.
  //   // isMobile: (state) => (state.innerWidth < 750 ? true : false)
  //   //address: (state) => this.$state.accountStore?.getAddress
  // },
  actions: {
    async init(params: any = {}) {
      if (this.$state.init && !params.forceInit && this.$state.init_in_progress) {
        return
      } else {
        this.$state.init_in_progress = true
      }


      this.$state.theme = localStorage.getItem("theme") ?? this.$state.theme ?? ''

      const maintenancePromise = this.checkIfDappMaintenance()

      // @ts-ignore: Object is possibly 'null'.
      if (this.$state.erdjs?.dapp?.getAccountStore()?.getAddress) {
        useLogger().log(
          // @ts-ignore: Object is possibly 'null'.
          `default/init ${this.$state.erdjs.dapp.getAccountStore().getAddress}`
        )

        // workaround for expired Native Auth Token (force logout)
        // @ts-ignore: Object is possibly 'null'.
        this.$state.loggedInTime = Number(
          localStorage.getItem("loggedInTime") ?? this.$state.loggedInTime
        )

        if (
          // @ts-ignore: Object is possibly 'null'.
          this.$state.erdjs?.dapp?.getAccountStore()?.getAddress &&
          !this.$state.loggedInTime
        ) {
          const currentDate = new Date()
          const unixTimestamp = Math.floor(currentDate.getTime() / 1000)
          // @ts-ignore: Object is possibly 'null'.
          this.$state.loggedInTime = unixTimestamp
          localStorage.setItem("loggedInTime", unixTimestamp.toString())
        } else if (this.$state.loggedInTime) {
          const currentDate = new Date()
          const unixTimestamp = Math.floor(currentDate.getTime() / 1000)
          const timeDifferenceInSeconds =
            unixTimestamp - this.$state.loggedInTime
          useLogger().log("timeDifferenceInSeconds", timeDifferenceInSeconds)
          if (timeDifferenceInSeconds > 3600) {
            useLogger().log("timeDifferenceInSeconds > 3600")
            await this.logout()
            window.location.href = "/"
          }
        }
      }

      let promises: any = [maintenancePromise]

      // @ts-ignore: Object is possibly 'null'.
      if (this.$state.erdjs?.dapp?.getAccountStore()?.getAddress) {
        // promises.push(useNodeApiStore().init())
        promises.push(useNetApiStore().init())
      }
      // promises.push(usePublicStore().init())

      await Promise.all(promises)
      // console.log('network config', state.erdjs.dapp.getDappStore().getNetworkConfig)
      // console.log('network chain id', state.erdjs.dapp.getDappStore().getChainId)
      this.$state.init_in_progress = false
    },
    async logout(forceDelete = false) {
      // @ts-ignore: Object is possibly 'null'.
      // first, logout from erdjs so the app won't call "init" again
      await this.$state.erdjs.dapp.logout()

      await Promise.all([
        useNodeApiStore().logout(),
        useNetApiStore().logout(),
        // @ts-ignore: Object is possibly 'null'.
        // this.$state.erdjs?.dapp?.logout(),
      ])

      // @ts-ignore: Object is possibly 'null'.
      this.$state.erdjs = undefined

      this.$state.loggedInTime = undefined
      localStorage.removeItem("loggedInTime")
      localStorage.removeItem("erdjs-app")
      localStorage.removeItem("erdjs-account")
      localStorage.removeItem("erdjs-login-info")

      if (forceDelete) {
        localStorage.clear()
      }
    },
    isAuthN() {
      // @ts-ignore: Object is possibly 'null'.
      return this.$state.erdjs?.dapp?.getLoginInfoStore()?.isLoggedIn
    },
    async checkIfDappMaintenance() {
      return false; // todo: implement
    },
    updateScreenSize(innerWidth: any, innerHeight: any) {
      this.$state.innerWidth = innerWidth
      this.$state.innerHeight = innerHeight
    },
    toggleTheme() {
      if (!this.$state.theme) {
        this.$state.theme = "light"
        localStorage.setItem("theme", "light")
      } else {
        this.$state.theme = ""
        localStorage.setItem("theme", "")
      }
    },
    async getTokens() {
      if (!this.$state.erdjs) {
        return
      }
      const [nativeAuthToken, jwt, jwt2] = await Promise.all([
        this.getNativeToken(),
        this.getJWT(),
        this.getJwt2()
      ])
      return {
        nativeAuthToken,
        jwt,
        jwt2
      }
    },
    async getNativeToken() {
      // NATIVE AUTH...
      const myPrecious =
        // @ts-ignore: Object is possibly 'null'.
        this.$state.erdjs.dapp.getLoginInfoStore().getTokenLogin ??
        // @ts-ignore: Object is possibly 'null'.
        this.$state.erdjs.dapp.getLoginInfoStore().getWalletConnectLogin ??
        // @ts-ignore: Object is possibly 'null'.
        this.$state.erdjs.dapp.getLoginInfoStore().getLedgerLogin ??
        // @ts-ignore: Object is possibly 'null'.
        this.$state.erdjs.dapp.getLoginInfoStore().getWalletLogin
      // console.log("myPrecious", myPrecious)

      if (myPrecious?.nativeAuthToken) {
        return myPrecious?.nativeAuthToken
        // const { signature, nativeAuthToken, loginToken } = getTokenLogin
        // // console.log("getTokenLogin", getTokenLogin)
        // console.log("signature", signature)
        // console.log("nativeAuthToken", nativeAuthToken)
        // return nativeAuthToken
      }
      // TODO: return dummy stupid value :)
      return await this.getJWT()
    },
    async getJWT() {
      // @ts-ignore: Object is possibly 'null'.
      const address = this.$state.erdjs?.dapp?.getAccountStore()?.getAddress
      const payload = {
        timestamp: new Date().getTime(),
        address
      }
      const secret = new TextEncoder().encode(process.env.VUE_APP_JWT_KEY)
      const alg = "HS256"
      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setIssuer("nftea.studio")
        .setAudience("api.nftea.studio")
        .setExpirationTime("24h")
        .sign(secret)
      return jwt
    },
    async getJwt2(params?: any) {
      if (!params || !params.nativeAuth) {
        // simple JWT auth
        if (!(this.$state.erdjs as any)?.dapp?.getAccountStore().getAddress) {
          return undefined
        }
        const payload = {
          timestamp: new Date().getTime(),
          wallet: (this.$state.erdjs as any)?.dapp?.getAccountStore().getAddress
        }
        // const token = jwt.sign(payload, process.env.VUE_APP_JWT_KEY, {
        //   expiresIn: "1hr"
        // });
        // const secret = new TextEncoder().encode(process.env.VUE_APP_JWT_KEY)

        const base64Key = process.env.VUE_APP_JWT_KEY;
        if (!base64Key) {
          return
        }
        // const secret = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0));

        const decodedSecret = decodeBase64(base64Key)
        // console.log('base64Key', base64Key)
        // console.log('decodedSecret', decodedSecret)

        // const s = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0));
        const secret = new TextEncoder().encode(decodedSecret)
        // console.log('secret', secret)

        const alg = "HS256"

        const jwt = await new jose.SignJWT(payload)
          .setProtectedHeader({ alg })
          .setIssuedAt()
          .setIssuer("urn:example:issuer")
          .setAudience("urn:example:audience")
          .setExpirationTime("2h")
          .sign(secret)

        return jwt
      }
    },
    async http(params: any, getToken = true) {
      const baseUrl = params.baseUrl ?? process.env.VUE_APP_NET_API
      const url = `${baseUrl}${params.path}`
      const headers = {
        ...params.headers
      }
      if (getToken) {
        const { nativeAuthToken, jwt, jwt2 }: any = await this.getTokens()
        headers.Authorization = `Bearer ${nativeAuthToken}`
        headers.Noitazirohtua = jwt
        headers.sht = jwt2
      }
      let options = {
        headers,
        data: params.data
      }
      return await Axios({ method: params.method, url, ...options })
    }
  }
})
