export const base64toUrl = (b64Data, contentType) => {  return `data:${contentType};base64,${b64Data}`
}

export const urlToBlob = async (url) => {
  return await fetch(url).then((res) => {
    return res.blob()
  })
}

export const urlToExtension = (url) => {
  return `.${url.split(";")[0].split("/")[1]}`
}

export const getUnixNow = () => {
  const currentDate = new Date()
  const unixTimestamp = Math.floor(currentDate.getTime() / 1000)
}

export const encodeBase64 = (data) => {
  return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data) => {
  return Buffer.from(data, 'base64').toString('ascii');
}